import * as React from "react";
import { Link } from "../components/Link";
import { StyledLink } from "../components/Link/style";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Title = styled.h1`
  margin: 0 auto;
  max-width: 700px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: -400px;
  margin-bottom: 200px;
  @media (max-width: 767px) {
    margin-top: -250px;
  }
`;

const BottomTitle = styled.h1`
  font-size: 400px;
  margin: 0;
  color: #ffffff0f;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 200px;
  }
`;

const Wrapper = styled.div`
  padding: 80px 0;
`;

const Subtitle = styled.div`
  font-size: 18px;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > ${StyledLink} {
    padding: 10px 20px;
    color: white;
  }
`;

const Divider = styled.div`
  max-width: 80px;
  height: 2px;
  background: white;
  margin: 40px auto;
`;

const NotFoundPage = ({ data }) => {
  const stickyCallToAction = data.datoCmsMainMenu.stickyCallToAction?.[0];
  return (
    <Layout>
      <Section
        textAlign="center"
        backgroundOverlay="Red"
        backgroundImage={
          data.datoCmsNotFoundPage.backgroundImage.gatsbyImageData
        }
      >
        <Wrapper>
          <BottomTitle>404</BottomTitle>
          <Title>We're sorry - it doesn't look like that page exists</Title>
          <Subtitle>You may want to try one of the following links:</Subtitle>
          <Divider />
          <MenuItemsWrapper>
            {data.datoCmsMainMenu.menuItems.map((menuItem) => (
              <Link to={menuItem.page.slug} key={menuItem.id}>
                <FontAwesomeIcon icon={faChevronRight} /> {menuItem.labelText}
              </Link>
            ))}
            {!!stickyCallToAction && (
              <Link to={stickyCallToAction.destinationPage.slug}>
                <FontAwesomeIcon icon={faChevronRight} />{" "}
                {stickyCallToAction.buttonText}
              </Link>
            )}
          </MenuItemsWrapper>
        </Wrapper>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  {
    datoCmsNotFoundPage {
      backgroundImage {
        gatsbyImageData
      }
    }
    datoCmsMainMenu {
      stickyCallToAction {
        ...Block_CallToActionButton
      }
      menuItems {
        id: originalId
        labelText
        page {
          ... on DatoCmsPage {
            id
            slug
          }
          ... on DatoCmsBlog {
            id
            slug
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
